<template>
  <div>
    <v-container class="pa-0" fluid>
      <v-row align="center" justify="center">
        <v-col sm="12" md="10" lg="8" xl="8" class="pt-0">
          <v-carousel
              height="auto"
          >
            <v-carousel-item
                v-for="(image,i) in shop.shop_images"
                :key="i"
            >

              <v-img
                  contain
                  :src="image.src"
                  width="100%"
                  eager
              />

            </v-carousel-item>
          </v-carousel>
        </v-col>
      </v-row>

      <v-card
          outlined
          tile
          class="border-none shop-card">
        <v-row align="center" justify="center">
          <v-col sm="12" md="10" lg="8" xl="8">
            <v-card-subtitle class="font-weight-bold">
              <v-chip
                  class="mr-2 mb-2"
                  color="black"
                  outlined
                  label
                  small
                  disabled

              >
                エリア
              </v-chip>
              {{shop.shop_area}}
              <br>
              <v-chip
                  class="mr-2 mb-2"
                  color="black"
                  outlined
                  label
                  small
                  disabled
              >
                ジャンル
              </v-chip>
              {{shop.shop_genre}}
            </v-card-subtitle>
            <div class="shop-title">
              <v-card-title class="font-weight-bold">
                {{shop.name}}
              </v-card-title>

              <v-card-subtitle>
                {{shop.furigana}}
              </v-card-subtitle>

              <v-card-subtitle>
                〒{{shop.zip}} {{shop.city}}{{shop.address1}}{{shop.address2}}<br>
                {{shop.shop_address_note}}
              </v-card-subtitle>
              <v-btn
                  tile
                  depressed
                  color="white"
                  class="icon-map"
              >
                <a :href="shop.shop_googlemap" target="_blank"><i class="fas fa-map-marker-alt mr-1"></i>Google Map</a>
              </v-btn>
            </div>
            <v-divider></v-divider>
            <v-card-text class="pb-0 newLine">
              {{shop.shop_ec_explanation}}
            </v-card-text>
          </v-col>
        </v-row>
      </v-card>
      <v-row align="center" justify="center" class="pa-4 pb-0">
        <v-col sm="12" md="10" lg="8" xl="8" class="pb-0">
          <h2>通販商品</h2>
        </v-col>
      </v-row>

<!--      <v-row align="center" justify="center" class="pa-4 pb-0">
        <v-col sm="12" md="4" lg="4" xl="4">
                    <v-text-field
            outlined
            label="search"
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row align="center" justify="center">
        <v-col sm="12" md="10" lg="8" xl="8" class="p-category text-center pt-0 mb-4">
          <a class="ma-2">ALL</a>
          <a class="ma-2">SALE</a>
          <a class="ma-2">category1</a>
          <a class="ma-2">category2</a>
        </v-col>
      </v-row>-->
      <v-row align="center" justify="center">
        <v-col sm="12" md="10" lg="8" xl="8">
      <v-row align="baseline" justify="start">

        <v-col sm="12" md="4" lg="4" xl="4" v-for="product in products" :key="product.no">
      <v-card
          outlined
          tile
          class="border-none shop-card"
          :to="`/product/detail/${product.makerNum}/${product.itemNum}`"
      >

              <v-img
                  contain
                  :src="product.imgMain"
                  width="100%"
              />

            <v-card-subtitle class="font-weight-bold pb-0">
<!--              <v-chip
                  class="mr-2 mb-2"
                  color="#e60044"
                  text-color="white"
                  label
                  small
                  disabled
              >
                10%OFF
              </v-chip>
              <v-chip
                  class="mr-2 mb-2"
                  color="#e60044"
                  text-color="white"
                  label
                  small
                  disabled
              >
                 test
              </v-chip>-->

            </v-card-subtitle>

            <v-card-title class="font-weight-bold pt-0">
              {{ product.itemName }}
            </v-card-title>

            <v-card-subtitle class="s-price">
              ¥{{product.price | toThousandFilter}}
            </v-card-subtitle>

      </v-card>
        </v-col>
      </v-row>
        </v-col>
      </v-row>
    </v-container>


  </div>
</template>

<script>
  import shops from "@/api/shops.js";
  import products from "@/api/products";

  export default {
    props: {
      shopId: String,
    },
    data() {
      return {

        // 店舗情報
        shop: [],

        // 商品情報
        products:[],
        productsQuery:{
          type:2,
          shop:null,
        },

      }
    },
    created() {

      // コース情報を取得
      this.productsQuery.shop = this.shopId
      products.fetchList(this.productsInfo, this.productsQuery);

      // 店舗情報を取得
      shops.fetch(this.shopInfo,this.shopId)

    },
    methods :{
      /** ____________________________________________________________________ API 店舗情報から取得  */
      async shopInfo(info) {
        this.shop = info.data[0];
      },
      /** ____________________________________________________________________ API 商品管理から取得  */
      async productsInfo(info) {
        this.products = info.data;
      },

    },
  }
</script>
<style scoped>
  @import "~@/styles/style.css";

  .v-text-field > .v-input__control > .v-input__slot::before{
    border-color: rgba(0, 0, 0, 0);
  }
  .btn-reserve button{
    width:100%;
    padding:32px;
  }
  .border-none {
    border:none!Important;
  }
  .card-pa0 {
    padding:0!important;
  }
  .icon-map i {
    color:#e60044;
    font-size:24px;
  }
  .v-text-field--filled {
    border-radius:0;
  }
  .select-menu {
    border-radius:0!important;
    height: 56px;
    overflow: hidden;
  }
  .btn-reserve button,
  .btn-reserve a
  {
    height: auto!important;
    padding-top: 24px!important;
    padding-bottom: 24px!Important;
    font-weight:bold;
  }
  .btn-register{
    text-align:center;
  }
  .theme--light.v-card > .v-card__text, .theme--light.v-card .v-card__subtitle {
    color: rgba(0, 0, 0, 0.87);
  }

  @media (max-width: 599px) {
    .sp-hide {
      display: none;
    }
  }
  .abrid-add {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    height: 6em;
  }
  .abrid-text {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5;
    overflow: hidden;
    height: 8em;
  }
  .p-category a {
    color:rgba(0, 0, 0, 0.87);
  }
</style>
